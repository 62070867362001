@import url('./color-fonts.css');
.banner {
    max-width: 1100px;
    position: relative;
    /* height: 500px; */
    height: 500px;
    margin: auto;
    display: flex;
    margin-top: 70px;
    
    /* background-color: red; */
    padding: 5%;
    font-family: var(--banner-font);
    overflow: hidden;
    /* z-index: 1000 !important; */
}

.banner>img {
    margin: auto;
    height: 500px;
}

.video-horizontal {
    position: absolute;
    top: 12%;
    left: 10%;
    width: 80%;
    /* width: 100%    !important; */
    /* height: 500px   !important; */
    z-index: -5000 !important;
}

/* .video-vertical {
    height: 0px;
    visibility: hidden;
    position: absolute;
    z-index: -6000;
} */

.banner__title {
    /* width: 60%; */
    /* height: 600px; */
    /* max-width: 600px; */
    width: 300px;
    display: block;
    margin: auto;
    margin-right: -10%;
    margin-top: 5%;
    /* background-color: rgb(255,255,255, 0.5); */
    background-color: white;
    /* border: 1px solid var(--theme-color-lighter); */
    padding: 2%;
    padding-bottom: 20px;
    padding-top: 0px;
    position: relative;
    z-index: 1000;
}

.banner__title>h1 {
    font-size: 35px;
    /* width: 90%; */
    text-align: left;
    /* margin-left: 10%; */
}

.banner__title>p {
    /* width: 90%; */
    /* margin-left: 10%; */
    /* font-size: 30px; */
    font-size: var(--subtitle-size);
    text-align: left;
    font-family: var(--panel-font);
    /* font-weight: 600; */
}

.banner__link{
    width: fit-content;
    padding: 4%;
    padding-left: 0px;
    /* margin-left: 20%;
    margin-top: 20%; */
    margin: auto;
    margin-left: 0px;
    font-size: 35px;
    text-align: left;
}

.banner__link>p{
    color: var(--theme-color-light);
    font-size: var(--subtitle-size) !important;
    font-weight: 600;
    text-align: left;
}

.banner__link>a{
    text-decoration: none !important;
}

@media (max-width: 1110px) {
    .banner {
        margin-top: 90px;
        width: inherit;
        display: block;
        padding: 0px;
        height: 500px;
    }

    .banner>img {
        margin: auto;
        width: 80vw;
        height: 80vw;
    }

    .banner__title {
        margin-top: 0px;
        margin-left: 0px;
        margin: auto;
        border: unset;
    }

    .banner__title>h1 {
        font-size: 25px;
    }

    .banner__title>img {
        width: 100%;
    }

    .banner__title>p {
        font-size: 15px;
    }

    .banner__link{
        /* width: 70%;
        margin-left: 0px;
        margin-top: 20px;
        font-size: 35px;
        text-align: left; */
    }
    
    .banner__link>p{
        color: var(--theme-color-light);
        font-size: 12px !important;
        font-weight: 600;
        text-align: left;
    }

    .video-horizontal {
        position: absolute;
        top: 265px;
        left: 5% !important;
        width: 90% !important;
        /* width: 100%    !important; */
        /* height: 500px   !important; */
        z-index: -5000 !important;
    }
}