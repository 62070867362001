.item {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    /* background-color: white; */
    z-index: 1000;

    /* display: block; */
}

.item__content {
    width: 1100px;
    height: 600px;
    background-color: white;
    position: absolute;
    top: calc((100% - 600px) / 2);
    left: calc((100% - 1100px)/2);
    z-index: 100;
    /* margin: auto; */
    border-radius: 20px;
    overflow: hidden;
}

.item__content::-webkit-scrollbar {
    display: none;
}

.item__details {
    display: flex;
    margin: auto;
    padding-top: 30px
}

.item__image__window {
    display: flex;
    height: 450px;
    margin: auto;
}

.item__image__bullets {
    width: 100px;
}

.item__title {
    position: sticky;
    height: fit-content;
    background-color: white;
    top: 0px;
    z-index: 500;
    padding: 20px;
}

.item__title>h2 {
    /* padding: 0px !important; */
    margin: 0px !important;
    /* margin-top: 10px !important; */
    display: block !important;
}

.item__images {
    /* width: 100%; */
    /* max-width: 500px; */
    background-color: whitesmoke;
    max-width: 500px;
    height: fit-content;
    margin: auto;
    padding: 5px;
}

.item__images>img {
    width: 400px;
    margin: auto !important;
}

.item__image {
    /* position: relative; */
    height: 75px;
    display: block;
}

.item__image>img {
    height: 40px;
    margin: auto;
    cursor: pointer;
}

.item__description {
    width: 400px;
    height: 400px;
    margin: auto;
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    padding: 20px;
    text-align: left;
    border: 1px solid var(--theme-color-lighter);
    border-radius: 20px;
    overflow: auto;
}

.item__image__bullets__horizontal {
    visibility: hidden;
}

.item__description>p {
    font-size: 14px;
}

.item__tabs {
    margin-top: 30px;
}

.item__tab__data > li{
    font-size: 13px;
}

.item__tab__headline {
    display: flex;
    width: 220px;
    justify-content: space-between;
    font-weight: 900;
}

.tab {
    width: 70px;
    text-align: center;
    cursor: pointer;
}

.tab>p {
    margin: 0px;
    font-size: 13px;
}

.selected__tab {
    width: 70px;
    text-align: center;
    border-bottom: 2px solid var(--theme-color-dark);
    font-weight: 1000;
    cursor: pointer;
}

.selected__tab>p {
    margin: 0px;
    font-size: 13px;
    margin-bottom: 5px;
}

.slick-track img {
    margin: auto;
}

.slider_window {
    visibility: hidden;
    width: 0px;
    height: 0px;
}

@media (max-width: 1100px) {
    .slider_window {
        visibility: unset;
        visibility: visible;
        height: 270px;
        width: 100%;
        display: block;
        text-align: center !important;
        justify-content: center !important;
    }

    /* .slick-dots li button:before{
    visibility: hidden !important;
    width: 0px !important;
    height: 0px !important;
}

.slick-list {
    visibility: hidden !important;
    width: 0px !important;
    height: 0px !important;
} */
    .item__content {
        width: 90%;
        height: fit-content;
        max-height: 80vh;
        left: 5%;
        top: 15%;
        overflow: auto;
    }

    .item__details {
        display: block;
        margin: unset;
        padding-top: 0px;
        overflow: auto;
    }

.item__tab__data > li{
    font-size: 13px;
}

    .item__image__window {
        height: 45vw;
        min-height: 250px;
        /* margin: unset; */
        /* margin-top: 0px; */
        display: block;
        text-align: center !important;
        justify-content: center !important;
    }

    .slider__item__image {
        height: 40vw !important;
        min-height: 220px !important;
        margin: auto !important;
        background-color: whitesmoke;
        /* margin-top:  !important; */
    }

    .slider__item__image>img {
        height: 40vw;
        min-height: 220px !important;
        /* height: fit-content; */
        /* margin-top: auto !important; */
        /* display: block; */
        margin: auto !important;
        margin-top: auto !important;
    }

    .item__content::-webkit-scrollbar {
        display: none;
    }

    .item__title>h2 {
        font-size: 20px !important;
    }

    .item__images {
        visibility: hidden;
        width: 0px;
        height: 0px;
        margin-top: 0px;
    }

    .item__images>img {
        width: 95%;
        margin-top: 0px;
    }

    .item__description {
        width: 85%;
        height: fit-content;
        padding: 20px;
        margin-bottom: 10px;
    }

    .item__description>h2 {
        font-size: 20px !important;
    }

    .item__image__bullets {
        visibility: hidden;
        width: 0px;
        height: 0px;
    }

    .item__image__bullets__horizontal {
        visibility: visible;
        display: flex;
        width: 95%;
        height: 50px;
    }

    .item__image__bullets__horizontal>img {
        width: 30px;
    }
}