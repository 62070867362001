/* @import url('./color-fonts.css'); */
.design{
    height: fit-content;
    /* background-color: whitesmoke; */
    font-family: var(--banner-font);
    position: relative;
    /* padding-top: 50px; */
    text-align: right;
    font-size: 16px;
    /* width: 20%; */
    padding-bottom: 50px;
    padding-top: 50px;
    /* background: linear-gradient(90deg, transparent 20%, var(--theme-color-lighter),var(--theme-color-lighter), var(--theme-color-lighter), transparent 80%) !important; */
}

.design__content {
    height: 100%;
    max-width: 1100px;
    display: block;
    margin: auto;
}

.design__content>p{
    width: fit-content;
    color: var(--headline-color);
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-right: -2px;
    text-align: left;
    margin: auto;
    /* width: 40%; */
    /* margin-top: 0px; */
}

.design__block__exurbia {
    width: 100%;
    height: 250px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.design__block__exurbia__text {
    width: 20%;
    margin-top: auto;
}

.design__block__exurbia__text>h1 {
    /* width: 20%; */
    font-size: 30px;
    letter-spacing: 5px;
    text-align: right;
    margin: auto;
    margin-right: 20px;
    /* margin-right: -5px; */
}

.design__block__exurbia__text>p {
    text-align: right;
    font-size: 16px;
    /* width: 20%; */
    margin-right: 20px;
}

.design__block__exurbia>img {
    height: 250px;
    width: 70%;
    box-shadow: var(--theme-color-lighter) 0px 5px 30px 0px;
}

.design__block__poche {
    width: 100%;
    margin: auto;
    margin-top: 50px;
    /* margin-bottom: 50px; */
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.design__block__poche__text {
    width: 20%;
    margin-top: auto;
}

.design__block__poche__text>h1 {
    font-size: 30px;
    letter-spacing: 5px;
    text-align: left;
    margin: auto;
    margin-left: 20px;
}

.design__block__poche__text>p {
    text-align: left;
    font-size: 16px;
    /* width: 20%; */
    margin-left: 20px;
}

.design__block__poche>img {
    height: 250px;
    width: 70%; 
    box-shadow: var(--theme-color-lighter) 0px 5px 30px 0px;
}

/* .video-horizontal {
    position: absolute;
    top: 0;

    left: 0;
    width: 40%    !important;
    height: inherit   !important;
    z-index: 1000;
} */

@media (max-width: 1100px) {
    .design {
        height: fit-content;
        /* margin-top: 5%; */
        padding-top: 30px;
    }
    .design>p{
        font-size: 30px;
    }
    .design__content {
        display: block;
    }
    .design__block__exurbia {
        width: 90%;
        /* margin-top: 80px; */
        height: fit-content;
        /* padding-top: 20px; */
        display: block;
        margin-bottom: 20px;
    }
    .design__block__exurbia__text{
        width: 90%;
        margin-top: 0px;
    }
    .design__block__exurbia__text>h1 {
        width: 250px;
        text-align: left;
        font-size: 22px;
        /* margin: unset; */
        margin-left: 20%;
    }
    .design__block__exurbia__text>p {
        text-align: left;
        width: 250px;
        margin-left: 20%;
    }
    .design__block__exurbia>img {
        width: 100%;
        margin-left: unset;
        margin: auto;
        height: unset;
        margin-top: 20px;
    }

    .design__block__poche {
        width: 90%;
        /* margin-top: 80px; */
        height: fit-content;
        /* padding-top: 20px; */
        margin-top: 20px;
        display: block;
    }
    .design__block__poche__text{
        width: 90%;
        margin-top: 0px;
    }
    .design__block__poche__text>h1 {
        width: 250px;
        text-align: left;
        font-size: 22px;
        /* margin: unset; */
        margin-left: 20%;
    }
    .design__block__poche__text>p {
        text-align: left;
        width: 250px;
        margin-left: 20%;
    }
    .design__block__poche>img {
        width: 100%;
        margin-left: unset;
        margin: auto;
        height: unset;
        margin-bottom: 20px;
    }
}