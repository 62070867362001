.selection {
    display: flex;
    height: 500px;
    width: 80%;
    margin: auto;
    overflow: hidden;
    /* margin-left: 200px; */
    /* border: 1px solid black; */
    /* background-color: black; */
    /* justify-content: space-around; */
}

