.recaptcha__container {
    position: absolute;
    /* left: 0; */
    transform: scale(0);
    background-color: black;
    /* height: 0px; */
    /* z-index: -10000000; */
    /* opacity: 0;
    visibility: hidden; */
    /* overflow: hidden; */
    /* width: 20px !important; */
    /* width: 20%; */
    /* margin */
}