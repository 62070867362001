.story__section {
    width: 100vw;
    height: fit-content;
    min-height: 100vh;
    /* background-color: transparent; */
    background-color: whitesmoke;
    position: relative;
    top: 0;
    margin-top: 0px !important;
    padding-top: 300px;
    /* padding-bottom: 300px; */
    /* text-align: left; */
}

.story__section>img {
    z-index: -1000;
    width: 100%;
    position: absolute;
    top: 15%;
    left: 0;
}

.story__panel {
    background-color: white;
    width: fit-content;
    height: fit-content;
    margin: auto;
    padding: 20px;
    border-radius: 20px;
    z-index: 1000;
    /* margin-top: 200px */
}

.story__background>img {
    width: 120%;
    top: 5%;
    left: -70%;
    right: 0;
    margin: auto;
    z-index: -100;
    transform: rotate(70deg);
}

.story__headline {
    /* width: 40%; */
    margin: auto;
    /* margin-top: 200px; */
    max-width: 800px;
    text-align: left;
    color: var(--theme-color-dark);
    /* background-color: white; */
    font-size: var(--headline-size);
    font-weight: 600;
    letter-spacing: 2px;
    /* padding-top: 0px; */
}

.story__headline>p {
    margin-top: 0px !important;
}

/* .story__panel{
    position: relative;
    width: 100%;
    z-index: 10000;
} */

.story__text {
    margin: auto;
    height: fit-content;
    max-width: 800px;
    /* margin-top: 100px; */
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    z-index: 100;
    text-align: justify;
}

.story__text>p {
    font-weight: 200 !important;
}

@media (max-width: 1100px) {
    .story__section {
        text-align: left;
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .story__panel {
        width: 80%;
        font-size: 30px;
        /* margin-bottom: 150px; */
    }

    /* .story__text {
            width: 80%;
            font-size: 20px;
        } */
}