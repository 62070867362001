@import url('./color-fonts.css');

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: block;
    text-align: center;
    color: white;
    /* border: 1px solid black; */
    /* background-color: var(--theme-footer-color); */
    background-color: var(--theme-feature-block-color);
    /* height: 10vh; */
    height: 100px;
    padding: 25px;
    /* padding-top: 6vh; */
    z-index: 0;
}

.footer__content {
    display: flex;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    color: var(--theme-color-dark);
    width: 60%;
    justify-content: space-between;
}

.footer__links {
    display: flex;
    text-align: left;
    width: 400px;
    justify-content: space-between;
}

.footer__links>p {
    cursor: pointer;
}

.footer__social {
    width: 100px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
}

.company__print {
    color: var(--theme-color-dark);
    font-size: 15px;
}

@media (max-width: 600px) {
    .footer {
        height: fit-content;
        margin-top: 0px;
        padding: 1%;
    }
    .footer__content {
        display: flex;
    }
    .footer__social {
        width: 200px;
    }
}