@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");
@import url('./color-fonts.css');

.content {
    width: 100%;
    height: fit-content;
    margin: auto;
    text-align: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
}

.content h2 {
    color: #fff;
    font-size: 40px;
    position: absolute;
    border: 1px solid var(--theme-color-light);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    display: block;
    margin: auto;
    transform: translate(-25%, 0%);
}

.content h2:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 2px var(--theme-color-light);
}

.content h2:nth-child(2) {
    color: var(--theme-color-dark);
    animation: animate 4s ease-in-out infinite;
}

.content:hover>h2:nth-child(2) {
    background-color: var(--theme-color-light);
}

@keyframes animate {

    0%,
    100% {
        clip-path: polygon(0% 45%,
                16% 44%,
                33% 50%,
                54% 60%,
                70% 61%,
                84% 59%,
                100% 52%,
                100% 100%,
                0% 100%);
    }

    50% {
        clip-path: polygon(0% 60%,
                15% 65%,
                34% 66%,
                51% 62%,
                67% 50%,
                84% 45%,
                100% 46%,
                100% 100%,
                0% 100%);
    }
}

/* @media (max-width: 1300px) {
    .content {
        width: 50%;
    }
} */