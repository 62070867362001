@import url('./color-fonts.css');
.exurbia {
    position: relative;
    display: block;
    width: 100%;
    height: fit-content;
    font-family: var(--banner-font);
    background-color: whitesmoke;
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: center;
}

.exurbia>p {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 50px;
    margin-right: -50px;
    margin-top: 0px;
}

.exurbia__background {
    position: relative;
    height: 400px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.exurbia__background>img{
    width: 100%;
}

.exurbia__headline {
    width: fit-content;
    margin: auto;
}

.exurbia__headline>img {
    height: 250px;
}

.exurbia__text {
    width: fit-content;
    max-width: 700px;
    height: fit-content;
    font-size: var(--subtitle-size);
    margin: auto;
    cursor: pointer;
}

/* .add__icon {
    font-size: var(--headline-size) !important;
    color: var(--theme-color-light) !important;
    font-weight:100 !important;
} */

.exurbia__text>p {
    padding: 10px;
    border-radius: 10px;
    border: 0.5px solid var(--theme-color-light);
}

.exurbia__text>p:hover {
    background-color: white;
}

.exurbia__images {
    display: flex;
    width: fit-content;
    justify-content: space-around;
    margin: auto;
}

@media (max-width: 1100px) {
    .exurbia>p {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 20px;
        margin-right: -20px;
        margin-top: 0px;
    }

    .exurbia__background {
        position: relative;
        height: fit-content;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }
    
    .exurbia__background>img{
        width: 100%;
    }

    .exurbia__text {
        width: 90%;
        height: fit-content;
        font-size: 18px;
        font-weight: 600;
        margin: auto;
    }
}