@import url('./color-fonts.css');
.colors {
    width: 100%;
    height: fit-content;
    margin: auto;
    display: flex;
    padding-top: 5%;
    padding-bottom: 5%;
    justify-content: center;
    align-items: center;
    font-family: var(--banner-font);
    background-color: transparent;
    background-color: white;
    background: linear-gradient(to right, lightgreen, skyblue);
}

.colors__background>img {
    width: 500px;
    /* position: absolute; */
    margin: auto;
    /* z-index: -100; */
    transform: rotate(0deg);
}

.colors__panel{
    position: relative;
    /* height: 500px; */
    margin: auto;
    width: 100%;
    /* z-index: 10000; */
    text-align: left;
}

.colors__headline {
    /* margin: auto; */
    color: var(--headline-color);
    font-size: var(--headline-size);
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 0px;
}

.colors__headline>p {
    margin-top: 0px !important;
}

.colors__text {
    font-size: var(--subtitle-size);
    z-index: 100;
}

.colors__text>p {
    font-weight: lighter;
    margin-bottom: 70px;
}

@media (max-width: 1100px) {
    .colors {
        width: 100%;
        /* height: 800px; */
        /* text-align: left; */
        display: block;
    }

    .colors__background {

    }

    .colors__background>img {
        width: 300px;
        margin: auto;
        transform: rotate(0deg);
    }

    .colors__panel {
        width: 80%;
        margin: auto;
        text-align: justify;
    }

    .colors__headline {
        /* width: 80%; */
        font-size: 30px;
    }

    .colors__text {
        /* width: 80%; */
        font-size: 20px;
    }
}