.header {
  display: flex;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
  background-color: white;
  /* flex-direction: column; */
  /* height: fit-content; */
  border-bottom: 0.5px solid var(--theme-color-light);
  /* padding: 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.header>img {
  margin: auto;
  margin-top: 0px;
  display: block;
  height: 50px;
  cursor: pointer;
  position: relative;
}

.header__links {
  /* width: 400px; */
  /* height: 60px; */
  margin: auto;
  margin-right: 50px;
  /* margin-bottom: 0px; */
  width: 100px;
  position: absolute;
  height: fit-content;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  color: var(--theme-color-dark);
  justify-content: space-around;
}

.header__links>p {
  /* margin-top: 15px !important; */
  /* height: fit-content; */
  margin: auto;
  width: 50px;
  cursor: pointer;
  /* margin: auto !important; */

}

.header__link__icon__contact {
  padding: 0px !important;
  margin: auto !important;
  margin-top: 4px !important;
  height: fit-content !important;
  cursor: pointer;
  /* margin-bottom: 0px; */
}

.header__link__icon__shop {
  padding: 0px !important;
  margin: auto !important;
  height: fit-content !important;
  width: 50px;
  padding: 0px;
  /* margin-bottom: 0px; */
}

@media (max-width: 700px) {
  .header {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .header>img {
    position: relative;
    margin-left: 20px;
    height: 40px;
  }

  .header__links {
    margin-left: auto;
    margin-right: 5px;
  }
}