@import url('./style/color-fonts.css');

html {
  /* height: 100%;
  width: 100%;
  margin-bottom: 0; */
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: var(--banner-font);
}

code {
  font-family: var(--banner-font);
}