.setdetails {
    /* width: 95%; */
    margin: auto;
    display: flex;
    font-size: var(--description-size);
}

.specsgrid {
    display: grid;
    /* gap: 2px; */
    grid-template-columns: 130px 120px 120px 120px;
    grid-template-rows: 40px auto;
    font-size: var(--description-size);
    text-align: center;
    /* background-color: var(--theme-color-lighter); */
    border: 1px solid var(--theme-color-lighter);
    border-radius: 10px;
}

.specsgrid-details {
    display: grid;
    /* gap: 2px; */
    grid-template-columns: 95px 95px 95px 95px;
    grid-template-rows: 40px auto;
    font-size: 12px;
    text-align: center;
    /* background-color: var(--theme-color-lighter); */
    /* border: 1px solid var(--theme-color-lighter); */
    border-radius: 10px;
}

.size1 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}

.size1fitted {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

.size1flat {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
}

.size1pillows {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
}

.size2 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
}

.size2fitted {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
}

.size2flat {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
}

.size2pillows {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
}

.size3 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
}

.size3fitted {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
}

.size3flat {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
}

.size3pillows {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 5;
}

.type1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}

.type2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
}

.type3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
}

.type4 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 6;
}

.price1 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 6;
}

.price2 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end: 6;
}

.price3 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 5;
    grid-row-end: 6;
}

.setdetails>p {
    /* width: 40%; */
    margin: auto;
    color: var(--headline-color);
    /* font-size: 25px; */
    font-size: var(--subtitle-size);
    font-weight: 700 !important;
    letter-spacing: 2px;
    padding-top: 0px;
    /* margin-top: 20px; */
    margin-bottom: 15px;
}

.setdetails__content {
    margin-top: 15px;
    margin-bottom: 25px;
    display: flex;
    /* padding: 2%; */
    /* margin: auto; */
    justify-content: space-around;
    /* background-color: whitesmoke; */
    border-radius: 10px;
}

.setdetails__content>p {
    /* margin: 5%; */
    margin: auto;
    /* margin-bottom: 20px; */
    width: 25%;
    text-align: center;
    font-size: 18px;
}

.setdetails__content>h4 {
    margin: auto;
    width: fit-content;
    font-size: 20px;
}

.setdetails__block {
    height: fit-content;
    /* margin-top: 30px; */
}

.setdetails__block>h3 {
    /* margin-top: 7px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.setdetails__sizes {
    /* margin-top: 35px; */
    display: flex;
    /* padding: 2%; */
    /* margin: auto; */
    justify-content: space-around;
    /* background-color: whitesmoke; */
    border-radius: 10px;
}

.setdetails__sizes>p {
    /* margin: 5%; */
    margin: auto;
    width: 25%;
    text-align: center;
    /* font-size: 20px; */
}

.setdetails__sizes>h4 {
    margin: auto;
    width: fit-content;
    font-size: 20px;
}

.setdetails__sizes__size {
    display: block;
    /* margin: auto; */
    width: 30%;
    /* font-size: 20px; */
}

.setdetails__sizes__size>h4 {
    margin: auto;
    width: 5px;
    font-size: 20px;
}

/* .setdetails__content__size> */

@media (max-width: 1120px) {
    .setdetails>p {
        font-size: 20px;
    }

    .setdetails>h4 {
        width: 80%;
        margin: auto;
        color: green;
        font-size: 20px;
        font-weight: 700 !important;
        letter-spacing: 2px;
        padding-top: 0px;
        margin-top: 50px;
        margin-bottom: 15px;
    }

    .setdetails__content {
        margin-top: 15px;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-around;
        border-radius: 10px;
    }

    .setdetails__content>p {
        width: 18%;
        font-size: 13px;
    }

    .setdetails__content__size {
        display: block;
        margin: auto;
        /* width: 250px; */
        font-size: 15px;
    }

    .setdetails__content__size>h4 {
        margin: auto;
        width: fit-content;
        font-size: 20px;
        padding: 0px;
    }

    .setdetails__sizes__size>p {
        font-size: 13px;
    }

    .specsgrid {
        display: grid;
        /* gap: 2px; */
        grid-template-columns: 85px 80px 80px 80px;
        grid-template-rows: 40px auto;
        font-size: 10px;
        text-align: center;
        /* background-color: var(--theme-color-lighter); */
        border: 1px solid var(--theme-color-lighter);
        border-radius: 10px;
    }

    .specsgrid-details {
        display: grid;
        /* gap: 2px; */
        grid-template-columns: 75px 75px 75px 75px;
        grid-template-rows: 40px auto;
        font-size: 9px;
        text-align: center;
        /* background-color: var(--theme-color-lighter); */
        border: 1px solid var(--theme-color-lighter);
        border-radius: 10px;
    }
}