@import url('./color-fonts.css');

.choices__section {
    display: flex;
    width: fit-content;
    max-width: 1040px;
    margin: auto;
    margin-top: 20px;
    padding: 10px;
    /* background-color: white; */
    justify-content: space-between;
    border-radius: 5px;
}

/* box-shadow: 1px 1px 3px gray; */

.add__icon {
    font-size: var(--headline-size) !important;
    color: var(--theme-color-light) !important;
    font-weight: 100 !important;
    margin: auto;
}

.cancel__icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    /* margin-left: auto !important; */
    /* margin-right: -50px !important; */
    /* top: 0;
    right: 0; */
    scale: calc(1.2);
    font-size: 20px !important;
    color: var(--theme-color-dark);
    cursor: pointer;
    z-index: 600;
}

.link__button {
    width: fit-content;
    font-size: 15px !important;
    color: var(--theme-color-dark) !important;
    border: 1px solid var(--theme-color-dark) !important;
    cursor: pointer !important;
}

.colored__link__button {
    width: fit-content;
    font-size: 20px !important;
    background-color: rgb(123, 25, 236) !important;
    color: white !important;
    /* border: 1px solid var(--theme-color-dark) !important; */
    cursor: pointer !important;
}

.colored__link__button:hover {
    background-color: rgb(168, 53, 245) !important;
}


.hr__line {
    margin: auto;
    border-top: 3px solid white;
}

.vr__line {
    scale: calc(0.5);
}

@media (max-width: 1100px) {
    .link__button {
        font-size: 14px !important;
        color: var(--theme-color-dark) !important;
        border: 1px solid var(--theme-color-dark) !important;
        cursor: pointer !important;
        margin: auto;
        margin-left: 0px !important;
    }
    .choices__section {
        display: block;
        margin: auto;
        padding: 0px;
    }

    .add__icon {
        font-size: 30px !important;
    }
}