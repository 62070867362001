.blog__data {
    max-width: 800px;
    padding: 50px;
    border-radius: 30px;
    width: 90%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 100px;
    text-align: justify;
    background-color: antiquewhite;
}

.blog__title {
    font-size: 30px;
    font-weight: 600;
}

.blog__heading {
    font-size: 25px;
    font-weight: 600;
}

.blog__para {
    font-size: 20px;
}

@media (max-width: 1100px) {
    .blog__data {
        /* max-width: 800px; */
        border-radius: 30px;
        width: 80vw;
        padding: 5%;
        margin: auto;
        margin-top: 150px;
        margin-bottom: 50px;
        text-align: left;
        background-color: antiquewhite;
    }

    .blog__title {
        font-size: 25px;
    }

    .blog__heading {
        font-size: 25px;
        font-weight: 600;
    }

    .blog__para {
        font-size: 20px;
    }
}