@import url("https://use.typekit.net/hto8vld.css");
@import url("https://fonts.googleapis.com/css?family=Varela+Round");
@import url("https://fonts.googleapis.com/css?family=Maven+Pro");

:root {
    --theme-color-dark: #16296E;
    --theme-color-light: #699cfc;
    --theme-color-lighter: #d6d8ff;
    --theme-box-text-color: #29128f;
    --theme-feature-block-color: #d6d8ff;
    --theme-box-border-color: #cdcfce;
    --theme-footer-color: #d6e3fe;
    --backdrop-wash: #f0f2f5;
    --banner-font: 'Maven Pro';
    --panel-font: 'Maven Pro';
    --headline-color: #16296E;
    --headline-font: Arial Narrow, sans-serif;
    --description-font: Arial Narrow, sans-serif;
    --headline-size: 40px;
    --subtitle-size: 22px;
    --description-size: 14px;
    --max-width: 1100px;
}

.highlight {
    background-color: rgb(205, 229, 205);
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    color: rgb(1, 98, 1);
    font-weight: 1000;
}

@media (max-width: 1100px) {
    :root {
        --theme-color-dark: #16296E;
        --theme-color-light: #699cfc;
        --theme-color-lighter: #d6d8ff;
        --theme-box-text-color: #29128f;
        --theme-feature-block-color: #d6d8ff;
        --theme-box-border-color: #cdcfce;
        --theme-footer-color: #d6e3fe;
        --backdrop-wash: #f0f2f5;
        --banner-font: 'Maven Pro';
        --panel-font: 'Maven Pro';
        --headline-color: #16296E;
        --headline-font: Arial Narrow, sans-serif;
        --description-font: Arial Narrow, sans-serif;
        --headline-size: 30px;
        --subtitle-size: 20px;
        --description-size: 14px;
        --max-width: 1100px;
    }
}