.photoslider {
    width: 100%;
    max-width: 1000px;
    height: fit-content;
    margin: auto;
    padding-top: 3%;
    padding-bottom: 3%;;
    background-color: whitesmoke;
    font-family: --var(--banner-font);
}

.photoslider__headline {
    width: 60%;
    height: fit-content;
    margin: auto;
    /* margin-top: 50px; */
    /* margin-bottom: 100px; */
    
}

.photoslider__headline>p {
    color: var(--headline-color);
    /* font-family: var(--headline-font); */
    font-size: 40px;
    letter-spacing: 2px;
    margin-top: 0px;
}

/* .bedsheet__images {
    width: 100%;
    display: flex;
    justify-content: center;

} */

.photoslider__tile {
    padding: 5%;
    padding-left: 0%;
    padding-right: 0%;
}

.photoslider__tile>img{
    width: 350px;
    margin: auto;
    /* margin-right: 20px; */
    background-color: white;
    box-shadow: rgb(0 0 0 / 20%) 1px 1px 3px 1px;
}

.photoslider__features {
    margin-top: 100px;
    width: fit-content;
    margin: auto;
    justify-content: center;
}

.photoslider__features>img {
    width: 60%;
    margin-bottom: 50px;
}

/* .slick-next:before {
    color: var(--theme-color-light)!important;
    font-size: 2vw!important;
    margin-left: 40px!important;
}
.slick-prev:before {
    margin-left: -90px!important;
    color: var(--theme-color-light)!important;
    font-size: 2vw!important;
} */
.slick-list {
    /* width: 100%; */
    /* max-width: 900px; */
    /* display: flex !important; */
    /* margin-left: 40px!important; */
    /* background-color: var(--backdrop-wash) !important; */
    /* padding-top: 20px!important; */
    /* padding-left: 10px; */
}
.slick-slider {
    /* margin: auto; */
    /* width: fit-content; */
    /* width: 90% !important; */
    /* border: 1px solid black !important; */
    /* display: flex !important; */
    /* margin-left: auto!important;
    margin-right: auto!important; */
    /* margin-bottom: 100px!important; */
    /* background-color: var(--backdrop-wash)!important; */

}

@media (max-width: 700px) {
    .photoslider {
        width: 90%;
        /* max-width: 1000px; */
        height: fit-content;
        margin: auto;
        padding-top: 3%;
        background-color: whitesmoke;
    }

    .photoslider__tile>img{
        width: 150px;
        margin: auto;
        /* margin-right: 20px; */
        background-color: white;
        box-shadow: rgb(0 0 0 / 20%) 1px 1px 3px 1px;
    }

    .photoslider__headline>p {
        color: var(--headline-color);
        /* font-family: var(--headline-font); */
        font-size: 25px;
        letter-spacing: 2px;
    }
}