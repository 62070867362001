@import url('./color-fonts.css');

.features {
    /* width: 100%; */
    position: relative;
    padding: 20px;
    /* width: 1100px; */
    margin: auto;
    /* background: linear-gradient(90deg, transparent, var(--theme-color-lighter),var(--theme-color-lighter), var(--theme-color-lighter),var(--theme-color-lighter), transparent) !important;  */
    /* background-color: var(--theme-feature-block-color); */
    background: linear-gradient(90deg, transparent 10%, var(--theme-color-lighter),var(--theme-color-lighter), var(--theme-color-lighter), transparent 90%) !important;
    z-index: 100;
    height: fit-content;
}

.features>p {
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 30px;
    margin-top: 0px;
    letter-spacing: 7px;
    margin-right: -7px;
}

.features__section {
    /* width: fit-content; */
    max-width: 800px;
    height: fit-content;
    margin: auto;
    /* padding: 1%; */
    /* padding-bottom: 1%; */
}

.features__tile {
    /* padding: 1%; */
    padding-left: 0%;
    padding-right: 0%;
    /* width: 50% !important; */
    text-align: center;
}

.features__tile>p {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    margin-top: 0px !important;
    /* height: 40px !important; */
}

.features__tile>img{
    width: 35%;
    margin: auto;
    border-radius: 100px;
    background-color: white;
    box-shadow: 1px 1px 5px gray;
}

.slider {
    margin: auto;
}

.slick-next:before {
    /* position: absolute;
    margin-left: -88vw!important;
    margin-top: -200px!important; */
    color: var(--theme-color-light)!important;
    font-size: 35px!important;
    cursor: pointer!important;
    margin-right: -40px!important;

}
.slick-prev:before {
    /* position: absolute;
    margin-top: -200px !important;
    margin-left: -0px!important; */
    color: var(--theme-color-light)!important;
    font-size: 35px!important;
    cursor: pointer!important;
}

.slick-list {
    /* width: 90%; */
    /* height: 400px;
    overflow: hidden; */
}

.slick-slider {
    /* margin: auto;
    width: 90%; */
}

@media (max-width: 1100px) {
    .features {
        /* height: 180px; */
        background: var(--theme-color-lighter) !important;
    }
    .features>p {
        font-size: 18px;
        letter-spacing: 1px;
        margin-right: -1px;
    }
    .features__tile>p {
        font-size: 15px;
        font-weight: 600;
        width: 100%;
    }
}