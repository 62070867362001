/* .landing {
    width: 90vw;
    margin: auto;
    display: block;
    align-items: center;
    justify-content: center;
} */

.landing__section {
    /* margin: auto; */
    /* margin-top: 5%; */
    width: 100%;
    overflow: hidden;
    position: relative;
    /* margin-bottom: 0px !important; */
}

.landing__section::-webkit-scrollbar {display:none;}

@media (max-width: 1100px) {
    .landing__section {
        /* margin: auto; */
        /* margin-top: 5%; */
        width: 100%;
        overflow: hidden;
    }
}