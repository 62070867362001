/* @import url('./color-fonts.css'); */
.values {
    background-color: whitesmoke;
    /* width: 100%; */
    padding: 5%;
}

.values__content {
    /* width: 95%; */
    max-width: 1100px;
    margin: auto;
    padding: 5%;
    justify-content: center;
    align-items: center;
    font-family: var(--banner-font);
    background-color: white;
    box-shadow: 1px 1px 5px grey;
    border-radius: 15px;
}

.values__background>img {
    margin: auto;
    /* z-index: -100; */
    transform: rotate(0deg);
}

.values__panel{
    display: flex;
    /* margin-left: auto; */
    position: relative;
    /* margin: auto; */
    padding: 5px;
    /* width: 400px; */
    height: fit-content;
    /* z-index: 10000; */
    text-align: left;
    justify-content: space-between;
}

.values__headline {
    /* margin: auto; */
    color: var(--headline-color);
    font-size: var(--headline-size);
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 0px;
    margin: auto;
}

.values__headline>p {
    margin-top: 0px !important;
}

.values__text {
    width: 50%;
    font-size: var(--subtitle-size);
    z-index: 100;
}

.values__text>p {
    font-weight: lighter;
}

.values__section {
    display: flex;
    margin: auto;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

@media (max-width: 1100px) {
.values {
    /* width: 90%; */
    /* width: 100vw; */
    margin: auto;
    /* margin-left: 0px; */
    text-align: left;
}

.values__content {
    /* width: 95vw; */
    /* position: relative; */
    /* width: 300px; */
    margin-left: 0px;
    margin: auto;
    /* display: block; */
}

.values__panel {
    width: 95%;
    display: block;
}

.values__section {
    width: 95%;
    display: block;
    padding: 0px;
}

.values__headline {
    width: 100%;
    font-size: 30px;
}

.values__text {
    width: 100%;
    font-size: 20px;
}

.values__section {
    width: 95%;
}
}