.value__tile {
    width: 25%;
    text-align: left;
    /* padding: 5px; */
    display: grid;
    /* margin-left: auto;
    margin-right: auto; */
    height: 200px;
}
.value__tile>h1 {

}
.value__tile>p {
    width: 90%;
}

.more__text {
    font-size: var(--description-size);
    background-color: transparent;
    border-color: transparent;
    color: blue;
    cursor: pointer;
}

@media (max-width: 1100px) {
    .value__tile {
        width: 95%;
        /* border: 1px solid var(--theme-feature-block-color); */
        border-radius: 10px;
        /* padding: 2%; */
        margin-left: 0px;
        height: fit-content;
    }
    .value__tile>p {
        width: 95%;
    }
}