.visual {
    position: relative;
    width: 1100px;
    height: 500px;
    /* background: linear-gradient(90deg, transparent 40%, white, white 100%) !important; */
    margin: auto;
    display: flex;
    overflow: hidden;
    padding-bottom: 120px;
}

.visual__text {
    /* position: absolute; */
    margin: auto;
    width: 30%;
}

.visual__text>p {
    font-size: 30px;
}

.video-horizontal {
    position: absolute;
    /* top: -10%; */
    left: 15%;
    width: 70%;
    /* width: 100%    !important; */
    /* height: 500px   !important; */
    z-index: -5000 !important;
}

@media (max-width: 1100px) {
    .visual {
        width: 0px;
        height: 0px;
        margin: 0px;
    }

    /* .video-horizontal {
        visibility: hidden;
        height: 0px !important;
        width: 0px !important;
    } */
}