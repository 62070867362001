.specifications {
    /* padding: 30px; */
    display: flex;
    background-color: whitesmoke;
    padding-top: 50px;
    /* padding-bottom: 50px; */
}

.specifications__panel {
    width: 1020px;
    /* padding: 10px; */
    /* max-width: 900px; */
    margin: auto;
    display: block;
    background: white;
    /* border-radius: 20px; */
    justify-content: space-evenly;
}

.specifications__block {
    width: 900px;
    height: fit-content;
    margin: auto;
    /* background-color: white; */
    padding: 30px;
    /* background-color: var(--theme-feature-block-color); */
    /* padding: 10px; */
    text-align: center;
    
    display: grid;
}

.specifications__block>p {
    margin: auto;
    color: var(--headline-color);
    /* font-size: 25px; */
    font-size: var(--subtitle-size);
    font-weight: 700 !important;
    letter-spacing: 2px;
    padding-right: 2px;
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
    height: fit-content;
    /* margin-bottom: 15px; */
}

.specifications__images {
    margin: auto;
    display: flex;
    width: fit-content;
    max-width: 1000px;
    justify-content: space-between;
}

.specifications__images>img {
    width: 300px;
    margin: auto;
    display: block;
    /* margin-bottom: 10px; */
    border-radius: 20px;
}



.featuresgrid {
    display: grid;
    /* gap: 2px; */
    grid-template-columns: 250px 250px 250px;
    grid-template-rows: 200px auto;
    font-size: var(--description-size);
    text-align: center;
    justify-content: center; 
    /* background-color: var(--theme-color-lighter); */
    border: 1px solid var(--theme-color-lighter);
    border-radius: 10px;
}

.image1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    display: block;
    margin: auto;
}

.image1>img{
    max-width: 200px;
    display: block;
    margin: auto;
}

.image2 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    display: block;
    margin: auto;
}

.image2>img{
    max-width: 200px;
    display: block;
    margin: auto;
}

.image3 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    display: block;
    margin: auto;
}

.image3>img{
    max-width: 200px;
    display: block;
    margin: auto;
}

@media (max-width: 1100px) {
    .specifications {
        display: block;
        flex-direction: vertical;
        justify-content: space-around;
        /* height: 750px; */
    }

    .specifications__panel {
        width: fit-content;
        display: block;
    }

    .specifications__block {
        width: 94%;
        height: fit-content;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        /* display: block; */
        background-color: white;
        /* box-shadow: 1px 1px 3px gray; */
        padding: 10px;
        border-radius: 20px;
        display: grid;
    }

    .featuresgrid {
        display: grid;
        /* gap: 2px; */
        grid-template-columns: 200px auto;
        grid-template-rows: 200px 200px 200px;
        font-size: var(--description-size);
        text-align: center;
        justify-content: center; 
        /* background-color: var(--theme-color-lighter); */
        /* border: 1px solid var(--theme-color-lighter); */
        border-radius: 10px;
    }


    .image1 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        display: block;
        margin: auto;
    }

    .image1>img{
        max-width: 200px;
        display: block;
        margin: auto;
    }

    .image2 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
        display: block;
        margin: auto;
    }

    .image2>img{
        max-width: 200px;
        display: block;
        margin: auto;
    }

    .image3 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
        display: block;
        margin: auto;
    }

    .image3>img{
        max-width: 200px;
        display: block;
        margin: auto;
    }
}