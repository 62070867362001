@import url('./style/color-fonts.css');

.App {
  text-align: center;
  margin-bottom: 0px;
  overflow-y: hidden;
  /* Hide vertical scrollbar */
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  position: relative;
  height: fit-content;
  min-height: calc(100vh - 150px);
  padding-bottom: 150px;
}

body::-webkit-scrollbar {
  display: none;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}