.selection__tile {
    display: grid;
    /* border: 1px solid black; */
    /* width: inherit; */
    /* position: relative; */
    padding: 5px;
    /* box-shadow: gray 0px 5px px 0px; */
    /* height: 400px; */
    height: fit-content;
    margin: auto;
}

.selection__tile__details {
    width: inherit;
    margin: auto;
    display: block;
    margin-left: auto;
    padding: 5px;
    background-color: white;
    /* border: 1px solid red; */
}

.selection__tile__details>img {
    width: 300px;
    height: 300px;
    margin: auto;
    display: block;
    padding: 10px;
    cursor: pointer;
    overflow: visible;
}

.selection__tile__details>h3 {
    font-size: 15px;
}

.selection__tile__details>p {
    font-size: 10px;
}

.selection__tile__bullets {
    width: 100px;
    /* background-color: gray; */
    justify-content: space-around;
    margin: auto;
    display: flex;
}
.bullet>p {
    margin: 0px;
    cursor: pointer;
}
.bullet>img {
    height: 40px;
    width: 40px;
    cursor: pointer;
}
.selected__img {
    border: 3px solid var(--theme-color-lighter);
}

@media (max-width: 700px) {

}