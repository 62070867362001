.patterns {
    font-family: var(--banner-font);
    /* position: relative; */
    /* height: 2100px; */
    /* z-index: 2000; */
    padding-top: 1%;
    text-align: center;
    /* background-color: var(--theme-footer-color); */
    background-color: whitesmoke;
}

.patterns__headline {
    width: fit-content;
    
    color: var(--headline-color);
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-right: -2px;
    margin: auto;
    margin-top: 0px;
}

.patterns__headline>p {
    margin-top: 0px;
    margin: 0px;
    /* font-weight: 400; */
}

.patterns__carousel {
    height: fit-content;
    /* position: relative; */
    /* overflow: visible; */
    width: 1100px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 30px;
    background-color: white;
    /* box-shadow: gray 0px 1px 10px 0px; */
    /* box-shadow: 1px 1px 10px grey; */
    /* border: 1px solid rgb(32, 32, 32); */
    /* background-color: var(--theme-color-lighter); */
}

.shop__window {
    height: 50px;
    background-color: whitesmoke;
}

@media (max-width: 1100px) {
    .patterns {
        margin-top: 3%;
    }
    .patterns__headline{
        font-size: 30px;
    }
}