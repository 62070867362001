.information{
    /* min-height: 400px; */
    height: 300px;
    width: 100%;
    /* position: relative; */
    /* background-color: rgb(214, 227, 254); */
    justify-content: space-around;
    /* background-color: var(--theme-feature-block-color); */
    /* z-index: 5000; */
    display: flex;
    /* padding-bottom: 40px; */
}

.information__purchase {
    margin: auto;
    /* height: 50%;
    width: 50%; */
    height: fit-content;
    display: flex;
    padding: auto;
}

.information__purchase>p {
    margin: auto;
    color: var(--theme-color-dark);
    font-size: 25px;
    width: 50%;
    /* margin-top: 30px;
    margin-bottom: 20px; */
    /* margin-bottom: 0px;
    padding-bottom: 0px !important; */
}

.information__purchase>img {
    height: 30px;
    cursor: pointer;
    border: 1px solid #08ce78;
    background-color: white;
    padding: 1%;
    border-radius: 10px;
    cursor: pointer;
}

.subscription__panel{
    display: block;
    margin: auto;
    /* width: 300px; */
}

/* .subscription__button{
    margin: auto !important;
    width: fit-content;
    color: var(--theme-color-dark) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    border-radius: 10px;
}
.subscription__button:hover{
    background-color: var(--theme-color-dark) !important;
    color: white !important;
} */


.subscription__button{
    margin: auto !important;
    /* margin: auto !important; */
    width: fit-content;
    height: 50px;
    color: var(--theme-color-dark) !important;
    border: 1px solid var(--theme-color-dark) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    border-radius: 10px;
}
.subscription__button:hover{
    background-color: var(--theme-color-dark) !important;
    color: white !important;
}

@media (max-width: 1100px) {
    .information {
        width: 95%;
        height: 300px;
        margin: auto;
        display: grid;
        justify-content: space-around;
    }

    .information__purchase {
        /* height: fit-content; */
        margin: auto;
        display: flex;
        justify-content: space-around;
        width: 95%;
        /* justify-content: center;
        align-items: center;
        padding: 0px; */
    }

    .information__purchase>p {
        /* height: 50px; */
        /* margin: auto; */
        text-align: center;
        width: 60%;
        font-size: 18px;
        padding: 0px !important;
    }

    .information__purchase>img {
        height: 20px;
        cursor: pointer;
        padding: 3%;
    }

    .subscription__button {
        font-size: 15px !important;
        height: 35px;
    }
}