.notice {
    min-height: 40vh;
    width: 100%;
    max-width: 700px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    /* background: linear-gradient(180deg, white 20%, var(--theme-feature-block-color) 90%); */
    /* position: relative; */
    overflow-y: auto;
    overflow-x: hidden;
}

.notice__tile {
    margin: auto;
    /* margin-top: 15vh; */
    display: block;
    width: 250px;
    height: 250px;
    box-shadow: rgb(0 0 0 / 20%) 1px 1px 3px 1px;
    border-radius: 20px;
    padding: 3%;
    justify-content: space-around;
    /* cursor: pointer; */
}

/* .notice__tile:hover {
    scale: calc(1.1);
    background-color: var(--theme-feature-block-color);
} */

.notice__tile>p {
    font-size: 25px;
    height: 60%;
    /* margin-bottom: 20% !important; */
    letter-spacing: 2px;
}

.notice__tile>img {
    height: 30px;
    cursor: pointer;
    /* border: 1px solid #08ce78; */
    /* background-color: white; */
    padding: 3%;
    border-radius: 10px;
    cursor: pointer;
}

.notice__subscription__panel{
    /* display: block; */
    margin: auto;
    margin-top: 10vh;
    /* width: 300px; */
}

.notice__subscribe{
    margin: auto !important;
    /* margin: auto !important; */
    width: fit-content;
    height: 50px;
    color: var(--theme-color-dark) !important;
    border: 1px solid var(--theme-color-dark) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    border-radius: 10px;
}
.notice__subscribe:hover{
    background-color: var(--theme-color-dark) !important;
    color: white !important;
}


@media (max-width: 950px) {
    .notice {
        display: block;
    }

    .notice__tile {
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
        width: 200px;
        height: 200px;
    }

    .notice__tile>p {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .notice__tile>img {
        height: 20px;
    }

    .notice__subscribe{
        margin: auto !important;
        width: fit-content;
        height: 33px;
        color: var(--theme-color-dark) !important;
        /* border: 1px solid var(--theme-color-dark) !important; */
        font-size: 17px !important;
        font-weight: 600 !important;
        border-radius: 10px;
    }

    .notice__subscription__panel{
        margin-left: 0px;
    }
}