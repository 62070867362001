.subscribe {
    width: 300px;
    height: 120px;
    margin: auto;
    /* margin-left: auto; */
    /* margin-top: 50px; */
    /* background-color: white; */
    padding: 20px;
    border-radius: 10px;
    z-index: 2000;
    box-shadow: rgb(0 0 0 / 20%) 1px 1px 3px 1px;
}

.subscribe>h2 {
    font-size: 25px;
    margin-top: 0px;
}

.subscribe>p {
    color: var(--theme-color-dark);
    font-weight: 600;
    margin-bottom: 30px;
}

.bot__icon {
    margin: 50px;
    scale: calc(3);
    color: red;
    padding: 10px;
    border: 0.5px solid red;
    border-radius: 50px;
}

.form__buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.button-0-2-32 {
    background-color: var(--theme-color-dark) !important;
    color: white;
    width: 100px !important;
    height: 40px !important;
    border-radius: 5px !important;
}

.form__buttons__button {
    background-color: var(--theme-color-dark) !important;
    color: white !important;
    width: 100px !important;
    height: 30px !important;
    font-size: 15px !important;
    border-radius: 5px !important;
}

.subscription__data {
    /* background-color: var(--theme-color-dark); */
    border: 0.5px solid var(--theme-color-dark);
    color: var(--theme-color-dark);
    width: fit-content;
    padding: 10px;
    font-weight: 600;
    border-radius: 10px;
    margin: auto;
    /* margin-top: 30px; */
    /* margin-bottom: 20px; */
}

.subscription__data>p {
    margin:0px;
}

@media (max-width: 600px) {
    .subscribe>h2 {
        font-size: 20px;
        margin-top: 0px;
    }
}