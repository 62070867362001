.blogs {
    /* width: 70%; */
    margin: auto;
    padding: 5%;
    padding-top: 0%;
    padding-bottom: 0%;
    /* display: flex; */
}

.blogs>h2 {
    font-size: var(--headline-size);
    color: var(--headline-color);
    /* text-align: left; */
}

.blog__tile {
    width: calc(100% / 2);
    /* padding: 5%; */
    padding-bottom: 1%;
    text-align: left;
}

.blog {
    border: 0.5px solid lightgray;
    border-radius: 10px;
    padding-left: 2%;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
    background-color: var(--theme-color-lighter);
}

.blog:hover {
    /* transform: scale(1.5); */
    background-color: gainsboro;
}

/* @media (max-width: 1100px) {
    .blogs>h2 {
        font-size: 30px;
    }
} */