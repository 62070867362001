.poche {
    position: relative;
    display: block;
    width: 100%;
    height: fit-content;
    font-family: var(--banner-font);
    background-color: whitesmoke;
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: center;
    /* border: 1px solid black; */
}

.poche>p {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 50px;
    margin-right: -45px;
    margin-top: 0px;
}

.poche__background {
    position: relative;
    height: 400px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.poche__background>img{
    width: 100%;
}

.poche__headline {
    width: fit-content;
    margin: auto;
}

.poche__headline>img {
    height: 250px;
}

.poche__text {
    width: fit-content;
    max-width: 700px;
    /* background-color: white; */
    height: fit-content;
    font-size: var(--subtitle-size);
    margin: auto;
    cursor: pointer;
}

.poche__text>p {
    padding: 10px;
    border-radius: 10px;
    border: 0.5px solid var(--theme-color-light);
}

.poche__text>p:hover {
    background-color: white;
}

.poche__images {
    display: flex;
    width: fit-content;
    justify-content: space-around;
    margin: auto;
}

@media (max-width: 1100px) {
    .poche>p {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 20px;
        margin-right: -20px;
        margin-top: 0px;
    }

    .poche__background {
        position: relative;
        height: fit-content;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }
    
    .poche__background>img{
        /* position: absolute; */
        /* top: 2%; */
        /* left: 0; */
        width: 100%;
    }

    .poche__text {
        width: 90%;
        height: fit-content;
        font-size: 18px;
        font-weight: 600;
        margin: auto;
    }
}