.shop {
    /* background-color: var(--theme-feature-block-color); */
}

.shop__panel {
    display: flex;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    justify-content: space-between;
}

.shop__content {
    margin: auto;
    width: 50%;
}

.shop__headline {
    color: var(--headline-color);
    font-size: var(--headline-size);
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 0px;
    width: 50%;
    text-align: left;
}

.shop_image {
    display: flex;
    height: fit-content;
    margin: auto;
}

.shop_image>a>img {
    /* position: absolute; */
    height: 70px;
    /* background: white; */
    background: var;
    display: block;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 1px #ccc, 0px 0px 0px 2px #aaa, 0px 2px 2px #666;
    /* margin: auto; */
    margin: auto;
    cursor: pointer;
}

.shop_image>a>img:hover {
    scale: calc(1.1);
}

.shop__text {
    font-size: var(--subtitle-size);
    text-align: left;
}

@media (max-width: 1100px) {

    .shop_image>a>img {
        /* position: absolute; */
        height: 50px;
    }
}